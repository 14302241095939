import React from "react";
import "./index.scss";

const UnderMaintenance = () => {
  return (
    <div className="under-maintenance">
      <div className="under-maintenance-heading">Site Under Maintenance</div>
    </div>
  );
};

export default UnderMaintenance;
