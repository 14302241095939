import axios from "axios";
export class serviceWrapper {
  static getAnimeList = async () => {
    // https://api.jikan.moe/v4/top/anime?page=1&limit=1000
    return await axios({
      method: "get",
      baseURL: "https://api.jikan.moe/v4",
      url: "/top/anime",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res);
  };

  static getAnimeEpisode = async (id: any, episode: any) => {
    // https://api.jikan.moe/v4/top/anime?page=1&limit=1000
    return await axios({
      method: "get",
      baseURL: "https://api.jikan.moe/v4",
      url: `/anime/${id}/episodes`,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res);
  };
}
